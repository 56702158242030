<template>
  <div class="container page">
    <!-- this.lottery.name -->
    <van-nav-bar :title="this.lottery.next_expect" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <div class="right" @click="$router.push({ path: '/GameRecord' });">
          <van-icon name="notes-o" size="28" />
          <!-- {{ $t("my.task_record") }} -->
        </div>
      </template>
    </van-nav-bar>
    <!-- <div class="record">
      <div class="period">
        <van-image class="cover" :src="this.lottery.ico">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
        <span class="period-number">{{ this.lottery.next_expect }}</span>
        <div class="next-number">
          <template v-if="time !== null">
            <van-count-down :time="time" @finish="check()" />
          </template>
        </div>
      </div>
      <div class="linear-gradient"
        style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
      </div>
     
    </div> -->
    <div class="history_popup"></div>
    <div class="wrapper">
      <div class="options-bar">

        <div class="right">
          <div class="sumValueTwoSides pink-sum">
            <div class="bet-btn-div">
              <div class="rectangle large pink-btn top-cmp" :class="{ active: choose[v.type] }"
                v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name);">
                <div class="wrapper">
                  <div class="content space-between-div"><img :src="v.img">
                    <p class="name-text large">{{ v.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="center">
            <div class="lottery-name-div" @click="gameListshow = true"><span class="center-lotter-name">{{
              this.lottery.name }}</span><i class="van-badge__wrapper van-icon van-icon-arrow-down icon down"
                style="font-size: 14px;"></i></div>

            <template v-if="time !== null">
              <van-count-down :time="time" @finish="check()">
                <template #default="timeData">
                  <div class="clock">
                    <span class="text">{{ getData(timeData.hours) }}</span>
                    <span class="label">:</span>
                    <span class="text">{{ getData(timeData.minutes) }}</span>
                    <span class="label">:</span>
                    <span class="text">{{ getData(timeData.seconds) }}</span>
                  </div>
                </template>
              </van-count-down>
            </template>
            <span class="center-deadline">{{$t("zhibo.期截止")}}</span>
          </div>
        </div>

        <!-- <div class="game">
          <div class="linear-gradient"
            style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
          </div>
          <div class="sumValueTwoSides">
            <div class="rectangle large" :class="{ active: choose[v.type] }" v-for="(v, key) in lottery_peilv_list"
              :key="key" @click="choosePlay(v.type, v.name);">
              <div class="wrapper">
                <div class="content">
                  <p class="name-text large">{{ v.name }}</p>
                  <p class="odd-text large" v-if="$store.getters.getBaseInfo.close_number == 0">{{ v.proportion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left"><img src="@/assets/zhibo/lottery-bottom-img.png">
          </div>
          <div class="mid"><span>{{$t("zhibo.权限")}}</span>
          </div>

          <div class="right" @click="jiesuan()">
            {{ $t("reservation.submit") }}
          </div>
        </div>
        <!-- <div class="bar">
          <div class="left">
            <div class="item" @click="shopping ? shopping = false : shopping = true">
              <van-icon name="cart-o" class="jixuanico" />
              <span class="text">{{ $t("reservation.task_list") }}</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="mid">
            <div><span class="text">{{ $t("reservation.available_balance") }}</span></div>
            <div><span class="text num">{{ this.userInfo.money }}</span><span class="text">{{ $t("reservation.unit")
            }}</span>
            </div>

          </div>
          <div class="right" @click="jiesuan()">
            {{ $t("reservation.submit") }}
          </div>
        </div> -->
        <div class="wrapper active">
          <div class="item">
            <div class="part"><span>{{$t("zhibo.共")}}</span><span class="number">{{ formData.length }}</span><span
                class="margin-span">{{$t("zhibo.个")}}</span><span>{{$t("zhibo.总认证")}}</span><span class="number">{{ money }}</span><span>{{$t("zhibo.票数")}}</span></div>
            <div class="part">
              <van-field class="input" input-align="center" v-model="money" type="digit"
                :placeholder="$t('reservation.price_place')" />
              <span>{{$t('my.balance')}}: {{ iseye ? this.userInfo.money:'****'  }} {{$t("zhibo.票数")}}</span>
              <van-icon v-if="iseye" @click="iseye = false" name="eye" />
              <van-icon  v-else @click="iseye = true" name="closed-eye" />
            </div>
          </div>
        </div>
        <!-- <div class="wrapper" :class="{ active: shopping }">
          <div class="item">
            <span class="label">{{ $t("reservation.curr_choose") }}：</span>
            <div class="bet-number">{{ this.shopchoose }}</div>
            <van-icon name="arrow-down" :class="{ up: !shopping, down: shopping }"
              @click="shopping ? shopping = false : shopping = true" />
          </div>
          <div class="item">
            <span class="label">{{ $t("reservation.per_price") }}</span>
            <div class="amount-wrapper">
              <van-field v-model="money" type="digit" :placeholder="$t('reservation.price_place')" />
              <span class="label">{{ $t("reservation.unit") }}</span>
            </div>
          </div>
          <div class="item">
            <div class="part">
              <span>{{ $t("reservation.total") }}</span>
              <span class="number">{{ this.formData.length }}</span>
              <span>{{ $t("reservation.note") }}</span>
            </div>
            <div class="part">
              <span>{{ $t("reservation.total") }}</span>
              <span class="number">{{ this.formData.length * this.money }}</span>
              <span>{{ $t("reservation.unit") }}</span>
            </div>

          </div>
        </div> -->
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">
              {{ this.lottery.next_expect }} <span style="color: #333;">{{$t("zhibo.投票单")}}</span>
            </p>
          </div>
          <ui class="list">
            <li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
              <div class="main">
                <p class="bet-name">{{ v.name }}</p>
                <p class="detail-text">1{{ $t("reservation.note") }} , {{$t('my.balance')}}：{{ money }}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </li>
          </ui>
          <div class="sub-bar">
            <van-button class="item cancel-btn" type="default" @click="allClear()">{{ $t("reservation.clear_order")
            }}</van-button>
            <van-button class="item sub-btn" :disabled="isDisabled" type="default" @click="doSub">{{
              $t("reservation.submit") }}</van-button>
          </div>
        </div>
      </van-popup>

      <van-popup v-model="gameListshow" position="top" :style="{ height: '100%' }">
        <!-- <van-grid :column-num="3">
          <van-grid-item @click="changeGame(v)" v-for="(v, key) in gameitemList" :key="key">
            <span>{{ v.name }}</span>
          </van-grid-item>
        </van-grid> -->
        <div class="group">

          <div class="wrapper">
          <div class="item" @click="changeGame(v)" v-for="(v, key) in gameitemList" :key="key">
            <div class="cover"><span class="name">{{ v.name }}</span></div>
          </div>
        </div>
        </div>
      </van-popup>
      <van-popup v-model="active" position="top" :style="{ height: '70%' }">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
          :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
          <div class="wrapper">
            <div class="item">
              <div class="left font-weight">{{ $t("reservation.num") }}</div>
              <div class="right font-weight">{{ $t("reservation.win_num") }}</div>
            </div>
            <div class="item" v-for="(v, key) in lottery_list" :key="key">
              <div class="left font-weight">{{ v.expect }}</div>
              <div class="right font-weight">
                <div class="kuaisan-ball left" style="justify-content: center;width: 100%;"
                  :style="{ 'margin-left': $store.getters.getBaseInfo.close_number == 1 ? '' : '20px' }">
                  <template v-if="$store.getters.getBaseInfo.close_number == 0">
                    <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[0] + '.png?v=3'">
                      <template v-slot:loading>
                        <van-loading type="spinner" />
                      </template>
                    </van-image>
                    <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[1] + '.png?v=3'">
                      <template v-slot:loading>
                        <van-loading type="spinner" />
                      </template>
                    </van-image>
                    <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[2] + '.png?v=3'">
                      <template v-slot:loading>
                        <van-loading type="spinner" />
                      </template>
                    </van-image>
                    <span class="res-des middle">{{ v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
                  </template>


                  <span class="res-des middle">{{ v.one }}</span>
                  <span class="res-des middle">{{ v.two }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-pull-refresh>
      </van-popup>
    </div>
  </div>
</template>

<script>
// var time;
// var count = 0;
import { Toast } from 'vant';
export default {
  data() {
    return {
      jiesuanpage: false,
      choose: {
        "bi": false,
        "sm": false,
        "si": false,
        "do": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
        "13": false,
        "14": false,
        "15": false,
        "16": false,
        "17": false,
        "18": false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: null,
      shopchoose: this.$t("reservation.no_choose"),
      gameitem: "",
      gameitemList: [],
      formData: [],
      money: 1,
      isDisabled: false,
      gameListshow: false,
      gamekey: '',
      iseye:true,
      gameid: ''
    };
  },
  methods: {
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitemList = res.data;
      })
    },
    getData(num) {
      return num < 10 ? '0' + num : num
    },
    back() {
      return window.history.back();
    },
    doSub() {
      if (this.money === "0") {
        this.$toast(this.$t("reservation.money_err"));
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t("reservation.price_place"));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t("reservation.balance_enough"));
          return false;
        } else {
          if (this.isDisabled) return
          this.isDisabled = true
          this.$http({
            method: 'post',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
            this.isDisabled = false
          })
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t("reservation.no_choose");
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {
      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t("reservation.price_place"));
        return false;
      }
      else {
        this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
      }

    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]['type']) {
          this.formData.splice(i, 1)
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("reservation.no_choose");
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({ 'name': name, 'type': type })
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("reservation.no_choose");
        this.gameitem = "";
        this.shopping = false;
      }

    },
    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        // time = window.setInterval(() => {
        //   setTimeout(()=>{
        //     count++;
        //     if(count > 5){
        //       clearInterval(time);
        //       count = 0;
        //     }
        //   },0)
        // }, 300)

        this.getUserInfo();
        this.getLotteryInfo();
        this.getLotteryList();
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        data: { id: this.gameid },
        url: 'lottery_get_peilv'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryList() {
      this.$http({
        method: 'get',
        data: { key: this.gamekey },
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryInfo() {
      this.$http({
        method: 'get',
        data: { key: this.gamekey },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          // if(parseFloat(this.userInfo.money) < parseFloat(res.data.condition)){
          //   this.$toast(this.$t("reservation.contact_admin"));
          //   this.$router.push({path:'/Home'})
          //   return false;
          // }
          this.lottery = res.data;
          this.time = res.data.second * 1000;
          Toast.clear()

      this.gameListshow = false;
          if (this.time / 1000 === 59) {
            this.$toast(this.$t("reservation.prize_succ") + this.lottery.now_expect);
          }
          this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png?v=3";
          this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png?v=3";
          this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png?v=3";
          this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          if (this.sum >= 11 && this.sum <= 18) {
            this.size = 'A';
          } else if (this.sum >= 3 && this.sum <= 10) {
            this.size = 'B';
          }
          if (this.sum % 2 === 0) {
            this.double = 'D';
          } else {
            this.double = 'C';
          }
        } else if (res.code === 401) {
          this.$toast({
            message: res.msg,
            duration: 3000
          });
        }
      })

    },
    getInfo() {
      Toast.loading({
        message: this.$t('refresh.loading'),
        forbidClick: true,
      });
      this.getLotteryInfo();
      this.getLotteryList();
    },
    changeGame(item) {
      this.gamekey = item.key
      this.gameid = item.id
      this.getInfo()
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.gamekey = this.$route.query.key
      this.gameid = this.$route.query.id
      this.getInfo()
      this.getUserInfo();
      this.getGameItem()
    }
  },
  destroyed() {
    // clearInterval(time);
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.nav-bar .right {
  padding-left: 8px;
  padding-right: 8px;
  color: #fa59b5;
  font-size: 28px;
  border-radius: 10px;
  // border: 2px solid #fa59b5;
  // line-height: 60px;
}

.record {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 #cacaca;
  z-index: 1;
}

.record .period {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.record .period .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.record .period .period-number {
  flex: 1;
  margin-left: 20px;
  margin-right: 10px;
  height: 50px;
  line-height: 50px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.van-count-down {
  color: #fff;
  font-size: 45px;
  margin-top: 10px;
  float: right;
}

.options-bar .right .center .clock {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 10px
}

.options-bar .right .center .clock .text {
  font-size: 40px;
  color: #ff253f;
  font-weight: 500
}

.options-bar .right .center .clock .label {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 40px;
  line-height: 50px;
  color: #ff253f;
  padding-bottom: 3%;
  font-weight: 500
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  // display: flex;
  // align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 20px;
  margin-right: 5px;
}

.van-icon {
  font-size: 40px;
}

.down {
  transition: all .5s;
}

.up {
  transform: rotate(180deg);
  transition: all .5s;
}

.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 80px);

  background: url(../../assets/zhibo/mine_bg.png);
  background-size: cover;
  background-position: 50%;

}

.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
}

.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: #ff253f;
}

.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}

::v-deep .van-icon-more-o {
  color: #ff253f;
  font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: #ff253f;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.sumValueTwoSides {
  display: flex;
  padding: 30px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.rectangle {
  overflow: hidden;
}

.rectangle.large {
  // margin: 0 0 30px 4%;
  width: 44%;
  border-radius: 10px;
}

.rectangle .wrapper {
  position: relative;
  padding: 0 10px;
  background: #fff;
}

.rectangle .wrapper .content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rectangle.large .wrapper {
  padding-bottom: 90%;
}

.rectangle .wrapper .content .name-text.large {
  font-size: 32px;
}

.rectangle .wrapper .content .name-text {
  color: #fff;
  font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  // margin-top: -30px;
  margin-top: 0px;
}

.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #ff253f;
}

.rectangle .wrapper .content img {
  height: 40px;
}

.bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 160px;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #cacaca;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar .left .item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 20px;
}

.bottom-bar .bar .left .item .text {
  font-size: 22px;
  color: #7d7c7c;
}

.bottom-bar .bar .left>img {
  height: 50px;
  margin: 0 10px;
}

.jixuanico {
  font-size: 45px;
}

.bottom-bar .bar .left .line {
  width: 2px;
  height: 50px;
  background: #dadada;
}

.bottom-bar .bar .mid {
  margin-left: 10px;
  flex: 1;
  font-size: 24px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
}

.bottom-bar .bar .mid .text {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.bottom-bar .bar .mid .text.num {
  margin: 0 5px;
  color: #ff253f;
}

.bottom-bar .bar .right {
  padding: 0 30px;
  margin: 0 30px;
  color: #fff;
  background: #ff4ea3;
  font-size: 35px;
  font-weight: 500;
  height: 70px;
  line-height: 70px;
  border-radius: 50px;
}

.rectangle.active .wrapper {
  // background-color: #ff253f !important;
  border: 2px solid #fa59b5 !important;
  background-color: rgba(250, 89, 181, .1) !important
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-popup {
  position: absolute;
}

::v-deep .van-overlay {
  position: absolute;
  background-color: rgb(70 67 67 / 70%);
}

::v-deep .van-popup--top {
  top: -1px;
}

.wrapper .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.wrapper .item .left {
  width: 40%;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.font-weight {
  font-weight: 700 !important;
}

.wrapper .item .right {
  flex: 1;
  display: flex;
  font-size: 30px;
  justify-content: center;
  overflow: hidden;
  color: #000;
}

.wrapper .item .kuaisan-ball .left {
  justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.wrapper .item .kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.wrapper .item .kuaisan-ball .res-des.middle {
  // width: 15%;
  width: auto;
  font-size: 35px;
}

.play-type-tip {
  position: unset;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 650px;
  height: 700px;
  max-height: 50%;
  z-index: 10;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}

.play-type-tip .title {
  line-height: 90px;
  background: linear-gradient(180deg, #fd4898, #fee0f9);
  ;
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 500;
}

.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}

.play-type-tip .wrapper {
  height: calc(100% - 10px);
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.play-type-tip .wrapper .item {
  padding: 40px 50px;
  display: flex;
  align-items: flex-start;
}

.play-type-tip .wrapper .item .van-icon {
  color: #c24491;
  font-size: 60px;
}

.play-type-tip .wrapper .item .content .content-title {
  margin-top: 22px;
  font-size: 35px;
  font-weight: 500;
  color: #000;
  line-height: 0px;
}

.play-type-tip .wrapper .item .content .content-detail {
  margin-top: 5px;
  font-size: 22px;
  color: #000;
  line-height: 30px;
}

.play-type-tip .wrapper .item .content {
  flex: 1;
  margin-left: 30px;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
  color: #ff253f !important;
}

.bottom-bar .wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  padding: 20px 20px 30px 20px;
  // height: 230px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px 0 #cacaca;
  transition: transform .3s cubic-bezier(.21, 1.02, .55, 1.01);
}

.bottom-bar .wrapper.active {
  transform: translateY(-100%);
}

.bottom-bar .wrapper .item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

.bottom-bar .wrapper .item .label {
  font-size: 30px;
  line-height: 30px;
  color: #000;
}

.bottom-bar .wrapper .item .bet-number {
  flex: 1;
  margin: 0 16px;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ff253f;
  font-size: 30px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}

.bottom-bar .wrapper .item .amount-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-cell {
  font-size: 30px;
  line-height: 50px;
}

.bottom-bar .wrapper .item .part {
  margin-right: 20px;
}

.bottom-bar .wrapper .item .part span {
  font-size: 30px;
  color: #000;
}

.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500;
}

::v-deep .van-field__control {
  color: #ff253f;
}

.confirm-order-modal {
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 20px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 680px;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 30px;
}

.confirm-order-modal .head {
  position: relative;
  height: 80px;
}

.confirm-order-modal .head .text {
  // padding: 0 20px;
  height: 30px;
  line-height: 10px;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #ff4ea3;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2px;
}

.van-popup--center {
  border-radius: 30px;
}

.confirm-order-modal .list {
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
  flex: 1;
  overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
  color: #ff253f;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
  word-wrap: break-word;
  word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
  line-height: 0px;
  font-size: 25px;
  color: #979799;
}

.confirm-order-modal .list .lise-item {
  color: #ff253f;
}

.confirm-order-modal .sub-bar {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
  min-width: 40%;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 35px;
  font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
  border: 2px solid #979799;
  color: #979799;
  background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
  background: #ff4ea3;
  color: #fff;
  border: 0;
}

.next-number span {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  float: right;
}





.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 200px);
  // background: url(../../static/img/mine_bg.png);
  background-size: cover;
  background-position: 50%
}

.options-bar .right {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative
}

.options-bar .right .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 10px
}

.options-bar .right .tips .odds {
  flex: 1;
  font-size: 28px;
  font-weight: 500;
  color: #ff253f
}

.options-bar .right .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%
}

.options-bar .right .tips .play-tip .icon {
  color: #ff253f
}

.options-bar .right .tips .play-tip .span-text {
  margin-left: 5px;
  font-size: 28px;
  font-weight: bolder;
  color: #ff253f;
}

.options-bar .right .center {
  position: absolute;
  width: 420px;
  height: 420px;
  top: 300px;
  left: calc(50% - 210px);
  background-image: url(../../assets/zhibo/lottery-center.png);
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.options-bar .right .center .lottery-name-div {
  margin-bottom: 15px
}

.options-bar .right .center .lottery-name-div .center-lotter-name {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px
}

.options-bar .right .center .lottery-name-div .icon {
  margin-left: 5px
}

.options-bar .right .center .lottery-name-div .icon.up {
  transform: rotate(180deg)
}

.options-bar .right .center .center-distance {
  font-size: 24px;
  color: #fff
}

.options-bar .right .center .clock {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 20px
}

.options-bar .right .center .clock .text {
  font-size: 30px;
  color: #ff253f;
  font-weight: 500
}

.options-bar .right .center .clock .label {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 32px;
  line-height: 32px;
  color: #ff253f;
  padding-bottom: 3%;
  font-weight: 500
}

.options-bar .right .center .center-deadline {
  font-size: 28px;
  color: #fff
}

.options-bar .bg-class {
  background-size: 100% 100%
}



.sumValueTwoSides {
  display: flex;
  padding: 20px 0;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start
}

.sumValueTwoSides .sum-img-bg {
  width: 960px;
  margin: 20px auto;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: .10667rem
}

.sumValueTwoSides .top-cmp2 {
  margin-bottom: 560px
}

.sumValueTwoSides .top-cmp {
  margin-bottom: 420px
}

.sumValueTwoSides .bet-btn-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap
}

.sumValueTwoSides .shop-mall-img {
  display: block;
  width: calc(100% - 40px);
  margin: 30px auto;
  -o-object-fit: contain;
  object-fit: contain
}

.sumValueTwoSides .short-bg-img {
  width: calc(100% - 320px) !important
}

.pink-btn .wrapper {
  background-size: cover !important;
  background-image: url(../../assets/zhibo/lottery-bet-bg.png) !important;
  background-position: 50% !important;
  background-color: transparent !important;
}



.bottom-bar .wrapper .item .pre-btn {
  padding: 10px 20px;
  color: #fa59b5;
  border: .02133rem solid #fa59b5;
  border-radius: 10px
}

.bottom-bar .wrapper .item .part {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.bottom-bar .wrapper .item .part .input {
  margin-right: 5px;
  width: 70px;
  padding: 0;
  line-height: 42px;
  border: 2px solid #ff4ea3;
  border-radius: 10px;
  font-size: 24px;
  background-color: #fff;
  margin-left: 5px
}

.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500
}

.bottom-bar .wrapper .item .part span {
  font-size: 24px;
  color: #000
}

.bottom-bar .wrapper .item .part .margin-span {
  margin-right: 20px
}

.bottom-bar .wrapper .item .part .eye-icon {
  font-size: 32px;
  margin-left: 20px;
  color: #000
}

.bottom-bar .wrapper .item .arrow-right {
  font-weight: bolder;
  color: #ff253f
}



.group {
    // margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px
}


.group .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.group .wrapper:active {
    opacity: .6
}

.group .wrapper .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 100px;
    padding: 0 8px;
    color:#fff;
}

.group .wrapper .item .cover {
    width: 90%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fa59b5;
    border-radius: 10px
}
.group .wrapper .item .cover .name {
  font-size: 24px;
}
</style>
