<template>
    <div class="welfare-container page">

        <div class="welfare-scroll">
            <div class="hot-game">
                <div class="hot-title-div">
                    <div class="title_bg">
                        <img src="@/assets/zhibo/hezuo.png">
                        {{ $t("zhibo.官方合作数据") }}
                    </div>
                </div>
                <div class="hot-items-div list-wrapper">
                    <!-- <ul class="">
                        <li @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
                            <div class="pad-content">
                                <van-image :src="v.ico">
                                    <template v-slot:loading>
                                        <van-loading type="circular" />
                                    </template>
                                </van-image>
                                
                            </div>
                        </li>
                    </ul> -->
                    <van-grid :column-num="3" :border="false">
                    <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
                        <van-image class="game_item_img" :src="v.ico" radius="10">
                        <template v-slot:loading>
                            <van-loading type="circular"/>
                        </template>
                        </van-image>
                        <span>{{v.name}}</span>
                        <span>{{v.desc}}</span>
                    </van-grid-item>
                    </van-grid>
                </div>
            </div>
            <div class="welfare-list">
                <ul>
                    <!-- <li>
                        <div><img src="@/assets/zhibo/new-fangpian.png"><span>防骗指南</span></div>
                        <div><i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
                        </div>
                    </li> -->
                    <li @click="nopage">
                        <div><img src="@/assets/zhibo/new-pipei.png"><span>{{ $t("zhibo.在线匹配") }}</span></div>
                        <div><i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
                        </div>
                    </li>
                    <li @click="nopage">
                        <div><img src="@/assets/zhibo/new-qiandao.png"><span>{{ $t("zhibo.每日签到") }}</span></div>
                        <div><i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
                        </div>
                    </li>
                </ul>
            </div><br>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Language",
    data() {
        return {

            gameitem: [{}, {}, {}, {}],
        };
    },
    created() {
        this.getGameItem()
    },
    mounted() {

    },
    methods: {

        toLottery(key, id) {
            if (!localStorage.getItem('token')) {
                this.$router.push({ path: '/Login' })
            } else {
                this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
            }
        },
        nopage() {
            this.$toast(this.$t("zhibo.没权限,请联系客服"));

        },
        getGameItem() {
            this.$http({
                method: 'get',
                url: 'lottery_list'
            }).then(res => {
                this.gameitem = res.data;
            })
        },
    },
};
</script>
  
<style lang='less' scoped>
@import "../../assets/css/base.css";


.welfare-container {
    height: calc(100% - 120px)
}

.welfare-container .welfare-scroll {
    width: 100%;
    height: calc(100% - 100px);
    margin: 0 auto;
    overflow-y: auto
}

.welfare-container .banner {
    height: 420px
}


.border-class {
    border-radius: 26px;
    border: 2px solid #766350
}

.hot-game {
    width: 95%;
    background: #fff;
    padding: 80px 0 20px;
    margin: 130px auto 20px;
    border-radius: 20px;
    border: 2px solid #ff4ea3;
    position: relative;
    overflow: visible
}

.hot-game>div {
    width: calc(100% - 50px);
    margin: 0 auto
}

.hot-game .hot-title-div {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    // width: 480px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1
}

.hot-game .hot-title-div>img {
    height: 100%
}

.hot-game .hot-items-div .three-column-ul li {
    width: 33%
}

.hot-game .hot-items-div ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.hot-game .hot-items-div ul li {
    width: 48%;
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    overflow: hidden
}

.hot-game .hot-items-div ul li .pad-content {
    padding-bottom: 51.8%;
    position: relative;
    background-color: #504f4f
}

.hot-game .hot-items-div ul li .pad-content .van-image,
.hot-game .hot-items-div ul li .pad-content .van-loading,
.hot-game .hot-items-div ul li .pad-content img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.hot-game .hot-items-div ul li .pad-content .overlay-div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center
}

.hot-game .hot-items-div ul li .pad-content .overlay-div span {
    font-size: 28px;
    font-weight: 500;
    color: #fff
}


.welfare-list {
    width: 95%;
    background: #fff;
    margin: 20px auto;
    border-radius: 20px
}

.welfare-list ul {
    width: 100%
}

.welfare-list ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: 95%;
    margin: 0 auto;
    color: #000
}

.welfare-list ul li>div {
    display: flex;
    align-items: center
}

.welfare-list ul li>div:first-child>img {
    height: 50px;
    margin-right: 20px
}

.welfare-list ul li>div:first-child>span {
    font-size: 24px;
    font-weight: 700
}

.welfare-list ul li>div:nth-child(2) {
    justify-content: center
}

.welfare-list ul li>div:nth-child(2)>span {
    font-size: 28px;
    color: #868686
}

.welfare-list ul li>div:nth-child(2)>div {
    width: 120px;
    height: 120px;
    margin: auto 20px;
    position: relative
}

.welfare-list ul li>div:nth-child(2)>div>img {
    height: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px
}

.welfare-list ul li>div:nth-child(2)>div>i {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    top: -10px;
    right: -10px
}

.welfare-list ul>li:not(:last-child) {
    border-bottom: 2px solid rgba(189, 192, 207, .5)
}

.page {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f4ebf3;
}

.title_bg {
    background: #E94FA7;
    border: 4px solid #B23174;
    padding: 10px 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fff;
    font-size: 35px;
}

.title_bg img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}
.game_item_img{
  width: 100px;
  height: 100px;
  border-radius: 40px;
}
.list-wrapper span{
  margin-top: 10px;
  font-size: 24px;
  color: #c24491;
}
</style>
  