<template>
    <div class="page">
        <!-- <van-nav-bar class="nav-bar">
        </van-nav-bar> -->
        <div class="home-scroll">

            <div class="describe">
                <ul>
                    <li>{{ $t("zhibo.全球第一福利品牌") }}</li>
                    <li>{{ $t("zhibo.在线视频/在线匹配/全网唯一/世界第一") }}</li>
                    <li></li>
                    <li>{{ $t("zhibo.更多精彩娱乐请联系客服") }}</li>
                </ul>
            </div>

            <div class="home-body">
                <ul>
                    <li>
                        <div class="title_bg">
                            <img src="@/assets/zhibo/jiangbei.png">
                            {{ $t("zhibo.本月主播排行榜") }}
                        </div>
                    </li>
                    <li>
                        <div class="pfpl" @click="$router.push({ path: '/live' });">
                            <img src="@/assets/zhibo/pfypl.png">
                            <span>
                                {{ $t("zhibo.评分与评论") }}
                            </span>
                        </div>
                        <div class="pfpl" @click="$router.push({ path: '/live' });">
                            <img src="@/assets/zhibo/pfypl.png">
                            <span>{{ $t("zhibo.素人推荐") }}</span>
                        </div>
                    </li>
                    <li>
                        <div><img src="@/assets/zhibo/jbyp.png"><img src="@/assets/zhibo/mvyp.png"></div>
                        <div><img src="@/assets/zhibo/jbjp.png"><img src="@/assets/zhibo/mvjp.png"></div>
                        <div><img src="@/assets/zhibo/jbtp.png"><img src="@/assets/zhibo/mvtp.png"></div>
                    </li>
                    <li><img src="@/assets/zhibo/jiangtai.png"></li>
                </ul>
            </div>

        </div>
    </div>
</template>
  
<script>
export default {
    name: "Language",
    data() {
        return {

        };
    },
    created() { },
    mounted() {
    },
    methods: {

    },
};
</script>
  
<style lang='less' scoped>
@import "../../assets/css/base.css";


.describe {
    width: 100%;
    height: 300px;
    border-radius: 30px;
    border: 2px solid #766350;
    margin: 30px 0 40px;
    background-image: url('../../assets/zhibo/header-bg.png');
    background-size: cover;
    background-position: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 5px 5px 5px;
    position: relative;
    overflow: hidden
}

.describe>ul {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column
}

.describe>ul li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.describe>ul>li:first-child {
    height: 120px;
    background-image: linear-gradient(180deg, #ff3aba, #fff);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 38px;
    font-weight: 700;
    text-align: center;
}

.describe>ul>li:nth-child(2) {
    height: 80px;
    font-size: 22px;
    color: #fff;
    letter-spacing: 2px;
    margin-bottom: 15px
}

.describe>ul>li:nth-child(3) {
    width: 840px;
    height: 2px;
    margin: 0 auto 40px;
    background-color: hsla(0, 0%, 100%, .39)
}

.describe>ul>li:nth-child(4) {
    height: 160px;
    font-size: 20px;
    color: #ff32a5
}

.home-scroll {
    width: calc(100% - 60px);
    height: calc(100% - 100px);
    margin: 0 auto;
    overflow-y: auto;
}



.home-body {
    width: 100%
}

.home-body>ul {
    display: flex;
    flex-direction: column
}

.home-body>ul>li {
    display: flex;
    align-items: center
}

.home-body>ul>li:first-child {
    justify-content: center
}

.home-body>ul>li:first-child>img {
    height: 70px
}

.home-body>ul>li:nth-child(2) {
    justify-content: space-between;
    padding: 0 40px
}

.home-body>ul>li:nth-child(2)>img {
    height: 160px
}

.home-body>ul>li:nth-child(3) {
    justify-content: center
}

.home-body>ul>li:nth-child(3)>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.home-body>ul>li:nth-child(3)>div>img:first-child {
    height: 80px
}

.home-body>ul>li:nth-child(3)>div>img:nth-child(2) {
    height: 120px;
    width: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%
}

.home-body>ul>li:nth-child(3)>div:first-child {
    margin-top: -20px
}

.home-body>ul>li:nth-child(3)>div:nth-child(2) {
    margin: -120px 50px 0
}

.home-body>ul>li:nth-child(3)>div:first-child>img,
.home-body>ul>li:nth-child(3)>div:nth-child(3)>img {
    margin-bottom: 18px
}

.home-body>ul>li:nth-child(4) {
    margin-top: -66px
}

.home-body>ul>li:nth-child(4)>img {
    width: 100%
}

.page {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f4ebf3;
    height: calc(100% - 80px);
}

.title_bg {
    background: #E94FA7;
    border: 4px solid #B23174;
    padding: 10px 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fff;
    font-size: 35px;
}

.title_bg img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.pfpl {
    position: relative;
    width: 200px;
    height: 200px;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    span {
        position: absolute;
        display: inline-block;
        // width: 70px;
        font-size: 22px;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 2;
    }
}
</style>
  